import React from "react";
import Layout from './Layout.js';

const Pagenotfound = () => {
  return (
    <Layout>
      
      <br/>
      <h1>Oops. Please check the website url.</h1>
      <br/>
      <br/>      
    </Layout>
  );
};

export default Pagenotfound;

