import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';

const Form = ({ onSubmit }) => {
  const [values, setValues] = useState({
    panCard: '',
    aadharCard: '',
    mobileNumber: '',
  });

  const [errors, setErrors] = useState({
    panCard: '',
    aadharCard: '',
    mobileNumber: '',
  });

//   const handleChange = (field) => (event) => {
//     setValues({ ...values, [field]: event.target.value });
//   };

  const handleChange = (field) => (event) => {
    const newValue = event.target.value;
    setValues({ ...values, [field]: newValue });
    // Reset errors when the user starts typing again
    setErrors({ ...errors, [field]: '' });
  };

//   const handleSubmit = () => {
//     onSubmit(values);
//   };

const handleSubmit = () => {
    // Validate phone number
    if (values.mobileNumber.length !== 10 || isNaN(values.mobileNumber)) {
      setErrors({ ...errors, mobileNumber: 'Phone number must be a 10-digit number' });
      return;
    }

    // Validate Aadhaar
    if (values.aadharCard.length !== 12 || isNaN(values.aadharCard)) {
      setErrors({ ...errors, aadharCard: 'Aadhaar must be a 12-digit number' });
      return;
    }

    // Validate PAN
    const panRegex = /^[A-Za-z]{5}[0-9]{4}[A-Za-z]$/;
    if (!panRegex.test(values.panCard)) {
      setErrors({ ...errors, panCard: 'PAN must be a 10-character alphanumeric code' });
      return;
    }

    // If all validations pass, submit the form
    onSubmit(values);
  };

  return (
    <React.Fragment>
    <CssBaseline />
    <Container maxWidth="sm">
      <Box sx={{ bgcolor: '#FAFAD2', borderRadius: '16px' }} >
      <Box sx={{ my: 5, ml: 5, "& h4": { fontWeight: "bold", mb: 2 } }}>
        <Typography variant="h4" >Enter your data:</Typography>
        </Box>
        <form >
      <Stack spacing={2} sx={{marginBottom: 4, marginTop:4, marginLeft:4, marginRight:4}}>
        <TextField
          label="Pan Card"
          placeholder="ABCDE1234F"
          value={values.panCard}
          onChange={handleChange('panCard')}
          error={!!errors.panCard}
          helperText={errors.panCard}
          inputProps={{ maxLength: 10 }}
          InputProps={{ style: { background: 'white' } }}
                    
        />
        <TextField
          label="Aadhar Card"
          placeholder="1234 5678 9012"
          value={values.aadharCard}
          onChange={handleChange('aadharCard')}
          error={!!errors.aadharCard}
          helperText={errors.aadharCard}
          inputProps={{ maxLength: 12 }}
          InputProps={{ style: { background: 'white' } }}
          
        />
        <TextField
          label="Mobile Number"
          placeholder="9876543210"
          value={values.mobileNumber}
          onChange={handleChange('mobileNumber')}
          error={!!errors.mobileNumber}
          helperText={errors.mobileNumber}
          inputProps={{ maxLength: 10 }}
          InputProps={{ style: { background: 'white' } }}
          
        />

        <Button variant="contained" color="primary" onClick={handleSubmit} mb={1}
              style={{
                backgroundColor: "black",
                color: "white",
                maxLength: 5,
                
              }} >
          Send OTP
          </Button>
      </Stack>
      
      
    </form>
    </Box>
    </Container>
  </React.Fragment>



  
  );
};

export default Form;
