import React, { useState } from 'react';



import "@aws-amplify/ui-react/styles.css";
import {
  withAuthenticator,
  Button
} from "@aws-amplify/ui-react";

import ApiResponseDisplay from './ApiResponseDisplay';
import Layout from './Layout';
import Form from './Form';
import { callBackendApi } from './api';
import { Box } from '@mui/material';

function Product({ signOut }) {

  //const [formValues, setFormValues] = useState(null);
  const [apiResponse, setApiResponse] = useState(null);

  // const handleFormSubmit = (values) => {
  //   setFormValues(values);
  // };


  const handleFormSubmit = async (values) => {
    try {
      // Call the backend API using the imported function
      const response = await callBackendApi(values);

      // Set the API response in the state to display to the user
      setApiResponse(response);

    } catch (error) {
      // Handle error if the API request fails
      console.error('Error calling backend API:', error);
      setApiResponse({ error: 'Failed to call the backend API' });
    }
  };

  return (
    <Layout>
      <br/>
                 {apiResponse ? (
              <ApiResponseDisplay response={apiResponse} />
            ) : (
              <Form onSubmit={handleFormSubmit} />
            )}

          
          <div style={{ flex: 1, paddingLeft: '40%', marginLeft: '1%', marginTop: '2%' }}>
            <Button size="medium" onClick={signOut}>Log Out</Button>
          </div>
             
    </Layout>

  );
}

export default withAuthenticator(Product);