// api.js
import axios from 'axios';

const backendApi = axios.create({
    baseURL: 'https://fohomcs4e6.execute-api.ap-south-1.amazonaws.com', // Set your backend API base URL
    headers: {
        'Content-Type': 'application/json',
        // Add any other headers as needed
    },
});

export const callBackendApi = async (formData) => {
    try {
        const response = await backendApi.get('/controller', formData);
        return response.data;
    } catch (error) {
        console.error('Error calling backend API:', error);
        throw new Error('Failed to call the backend API');
    }
};
