import React from 'react';
import Layout from './Layout';
import Container from '@mui/material/Container';


import Box from "@mui/material/Box";

const ApiResponseDisplay = ({ response }) => {
  return (
    <Container maxWidth="sm">
      <Box sx={{ bgcolor: '#FAFAD2', borderRadius: '16px' }} >
    <div>
      <h2>API Response</h2>
      <pre>{JSON.stringify(response, null, 2)}</pre>
    </div>
    </Box>
    </Container>
  );
};

export default ApiResponseDisplay;
