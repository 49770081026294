import React from "react";
import Layout from './Layout';
import { Link } from "react-router-dom";
import Banner from "../images/rupee-ring.png";
import "../styles/HomeStyles.css";

const Home = () => {
  return (
    <Layout>
      <div className="home" style={{ backgroundImage: `url(${Banner})` }}>
        <div className="headerContainer">
          {/* <h1>Big Dhan</h1>
          <p>Finance For All</p> */}
          <Link to="/product">
            <button>Try Now</button>
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default Home;
